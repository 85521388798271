import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RequestApiService } from "../../../../services/api/request/request-api.service";
import { ApiService } from '../../../../services/api/api.service';
import { UserSearchService } from '../../../../services/user-search.service';
import * as QuillNamespace from 'quill';
import { AutoComplete } from 'primeng/autocomplete';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-request-thread-actions',
  templateUrl: './request-thread-actions.component.html',
  styleUrls: ['./request-thread-actions.component.css']
})
export class RequestThreadActionsComponent implements OnInit {

  @Input() requestDetails;
  @Input() requestOptions;
  @Input() requestThread;
  @Input() selectedThreadId;
  @Input() userpermissions;
  @Output() refreshData = new EventEmitter<any>();
  @Output() threadIdChanged = new EventEmitter<any>();
  @ViewChild('typedValue') typedValue: AutoComplete;

  sendingInProgress = false;
  uploadingAttachmentInProgress = false;
  newThreadContent;
  attachmentArray = [];
  selectedAction = 'reply';
  forwardOriginalRequest = false;
  resolveChildTickets:boolean | null = null;
  newEmailToAddress: any;
  resolutionCodeId;

  // Timer
  timer_enabled = false;
  timer_start = null;
  timer_manual = false;

  timer_manual_Hours = 0;
  timer_manual_Minutes = 0;

  showCc = false;
  addCcField = '';
  newccfield: any[];
  suggestedEmails: any[];
  suggestedEmailsTo: any[];
  suggestedEmailsfiltered: any[];
  updatingCc = false;
  updatingnewCc = false;

  resolutionValidationError;
  quill: any;
  isImageUploaded: boolean = true;

  IsResolvedEmailNotification = true;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }],
      ['clean'],
      ['link', 'image']
    ]
  };

  constructor(private toastr: ToastrService, private requestApi: RequestApiService, private api: ApiService, public userSearchService: UserSearchService) {
  }

  ngOnInit(): void {

    if (this.requestDetails.cc.length > 0) {
      this.showCc = true;
    }

    if(this.requestDetails.MIModuleEnabled) {
      this.selectedAction = 'comment';
    }

    if (this.isCmdbEnabled()){
      this.selectedAction = 'comment';
    }

    this.timer_CheckEnabled();
  }



  onEditorChange(files: FileList) {
    if (files.length > 0) {
      const file = files[0];
      if (!this.isImageURL(file.name)) {
        this.isImageUploaded = false;
      } else {
        console.log('Uploaded image:', file.name);
        this.isImageUploaded = true;
      }
    }
  }

  isImageURL(url: string): boolean {
    const imageExtensions = /\.(jpeg|jpg|gif|png|bmp)$/i;
    return imageExtensions.test(url);
  }

  isCmdbEnabled(): boolean {
    return this.requestDetails.CmdbEnabled === 1;
  }

  timer_CheckEnabled() {
    this.api.get("/api/timer/settings").then(response => {
      console.log(response);
      if (response.TimeTrackingEnabled && !this.isCmdbEnabled())  {
        this.timer_enabled = true;
        this.timer_StartTimer();
      }
    })
  }

  timer_ResetTimer() {
    if (this.timer_enabled) {
      this.timer_StartTimer();
      this.timer_manual = false;
      this.timer_manual_Hours = 0;
      this.timer_manual_Minutes = 0;
    }
  }

  timer_StartTimer() {
    console.log("Starting Timer");
    this.timer_start = new Date();

    setInterval(() => {
      this.timer_start = this.timer_start;
    }, 1000);

  }


  timer_ElapsedMilliseconds() {
    if (!this.timer_start) {
      return 0;
    }
    // @ts-ignore
    var elapsed = new Date() - this.timer_start;
    //console.log("Elapsed:"+elapsed);
    return elapsed;
  }

  timer_ManualElapsedSeconds() {

  }

  timer_ElapsedSeconds() {
    if (this.timer_manual) {
      let elapsedSeconds = 0;
      elapsedSeconds = elapsedSeconds + this.timer_manual_Hours * 60 * 60;
      elapsedSeconds = elapsedSeconds + this.timer_manual_Minutes * 60;
      return elapsedSeconds;
    }
    return this.timer_ElapsedMilliseconds() / 1000;
  }


  msToTime() {
    var duration = this.timer_ElapsedMilliseconds();
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds: string | number = Math.floor((duration / 1000) % 60),
      minutes: string | number = Math.floor((duration / (1000 * 60)) % 60),
      hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  escalateToVendor() {
    this.requestApi.getVendorEscalateInfo(this.requestDetails.Id).then(response => {
      this.selectedAction = 'newEmail';
      // this.changeThread(null);
      this.newThreadContent = response.CompiledEmail;
      this.newEmailToAddress = response.EscalateEmailAddress;
    })
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {

      this.uploadingAttachmentInProgress = true;

      let fileName = file.name;
      let fileSize = file.size;

      const formData = new FormData();
      formData.append('0', file);

      this.requestApi.uploadTempAttachment(formData).then(success => {
        this.attachmentArray.push({
          AttachmentId: success.Id,
          AttachmentName: success.AttachmentName
        });

        this.uploadingAttachmentInProgress = false;

      }, error => {
        console.error(error);
      })
    }
  }

  removeTempAttachment(index) {
    this.attachmentArray.splice(index, 1);
  }

  getReplyingToPreview() {

    let threadObject = this.requestThread.Threads.find(thread => thread.ThreadId == this.selectedThreadId);
if(threadObject)
  {
    if (threadObject.IsMainThread) {
      return `Main Thread (${this.requestDetails.RequesterEmailAddress})`;
    }
    if (threadObject.IsVendorThread) {
      return "Vendor";
    }
    return threadObject.ToAddress;
  }

  }

  actionButtonLabel() {
    switch (this.selectedAction) {
      case 'reply':
        return "Reply";
      case 'newEmail':
        return "Send E-mail";
      case 'comment':
        return "Comment"
      case 'forward':
        return "Forward"
      case 'resolve':
        return "Resolve"
    }
  }

  addCC() {
    this.updatingCc = true;
    this.requestApi.addCC(this.requestDetails.Id, this.addCcField).then(success => {
      this.refreshParent();
      this.updatingCc = false;
      this.addCcField = '';
    }).catch(error => {

      // Errors popup anyway, reset it so the user can try again....
      this.updatingCc = false;
      this.addCcField = '';
    })
  }

  filterEmailTo(event) {
    let filtered = [];
    let emailfiltered = [];
    let query = event.query;
    this.userSearchService.filteredUsers(query).forEach(result => {
      filtered.push(result)
    })
    //this.suggestedEmailsTo = filtered;
    if (!this.newEmailToAddress || this.newEmailToAddress == undefined || this.newEmailToAddress.length == 0) {
      this.suggestedEmailsTo = filtered;
    }
    if (this.newEmailToAddress && this.newEmailToAddress.length > 0) {
      this.suggestedEmailsTo = filtered.filter(val => !this.newEmailToAddress.includes(val));
    }

  }

  filterEmailCc(event) {
    let filtered: any[] = [];
    let query = event.query;
    this.userSearchService.filteredUsers(query).forEach((result, index) => {
      if (this.newEmailToAddress !== undefined) {
        if (result.EmailAddress !== this.newEmailToAddress.EmailAddress) {
          filtered.push(result)
        }
      }
      if (this.newEmailToAddress == undefined) {

        filtered.push(result)
      }

    })
    if (!this.newccfield || this.newccfield == undefined || this.newccfield.length == 0) {
      this.suggestedEmails = filtered;
    }
    if (this.newccfield && this.newccfield.length > 0) {

      this.suggestedEmails = filtered.filter(val => !this.newccfield.includes(val));
    }
  }

  removeCC(ccId) {
    this.updatingCc = true;
    this.requestApi.removeCC(this.requestDetails.Id, ccId).then(success => {
      this.refreshParent();
      this.updatingCc = false;
    })
  }

  getCannedResponse(id) {
    this.requestApi.getCannedResponseCompiled(this.requestDetails.Id, id).then(response => {
      this.newThreadContent = response.Compiled;
    })
  }
  removeDuplicates(array: number[]): number[] {
    return Array.from(new Set(array));
  }

  onValueSelect(event: any) {
    // added because its there on UI, for later use
  }

  private isValidEmailAddress(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  public addCustomItem(event: KeyboardEvent, source: any) {
    const inputElement = event.target as HTMLInputElement;
    const inputText = inputElement.value.trim();

    if (inputText.trim().length == 0) {
      return;
    }

    if (source == 'to') {
      // check inputText if valid addess
      if (this.isValidEmailAddress(inputText)) {

        if (inputText && !this.suggestedEmailsTo.some(item => item.EmailAddress === inputText)) {
          // Add to suggestions
          const newItem = { EmailAddress: inputText };
          this.suggestedEmailsTo = [...this.suggestedEmailsTo, newItem];
  
          if (this.newEmailToAddress == 'undefined' || this.newEmailToAddress == null) {
            this.newEmailToAddress = [];
          }
  
          // Add to selected items
          this.newEmailToAddress = [...this.newEmailToAddress, newItem];
        }    
  
      } else {
        // throw error
        this.toastr.warning(`Validation Error: ${inputText} is invalid email format`);
      }

    } else if (source == 'cc') {
      if (this.isValidEmailAddress(inputText)) {
        if (inputText && !this.suggestedEmails.some(item => item.EmailAddress === inputText)) {
          // Add to suggestions
          const newItem = { EmailAddress: inputText };
          this.suggestedEmails = [...this.suggestedEmails, newItem];
    
          if (this.newccfield == null) {
            this.newccfield = [];
          }
  
          // Add to selected items
          this.newccfield = [...this.newccfield, newItem];
        }    
  
      } else {
        // throw error
        this.toastr.warning(`Validation Error: ${inputText} is invalid email format`);
      }
    }
  }  

  submit() {
    this.sendingInProgress = true;
    if (this.selectedAction == 'reply') {
      this.requestApi.threadReply(this.requestDetails.Id, this.newThreadContent, this.attachmentArray, this.selectedThreadId, null, false, this.timer_ElapsedSeconds(), this.resolveChildTickets).then(response => {
        this.refreshParent()
        this.clearForm();
      }).catch(error => {
        // if theres an issue, it will reset
        this.refreshParent()
        this.clearForm();
      });      
    }
    if (this.selectedAction == 'newEmail') {
      const autocompleteValue = this.typedValue.value;
      let newemail;
      if (typeof autocompleteValue === 'string') {
        newemail = autocompleteValue;
      } else if (typeof autocompleteValue === 'object') {
        newemail = autocompleteValue.EmailAddress;
      } else {
        console.log('Neither a string nor an object.');
      }

      //handles the TO array
      let toaddressarray = [];
      let touniqueArray = [];
      touniqueArray = this.removeDuplicates(this.newEmailToAddress);
      touniqueArray.forEach(email => {
        toaddressarray.push(email.EmailAddress)
      })

      //handles the CC array
      let ccaddressarray = [];
      let uniqueArray = [];
      uniqueArray = this.removeDuplicates(this.newccfield);
      uniqueArray.forEach(email => {
        ccaddressarray.push(email.EmailAddress)
      })
      this.requestApi.threadNew(this.requestDetails.Id, this.newThreadContent, this.attachmentArray, 'new', ccaddressarray, false, this.timer_ElapsedSeconds(), false, toaddressarray).then(response => {
        const threadId = response.ThreadId;

        // After new e-mail, select the new thread and set action to "reply"
        this.changeThread(threadId);
        this.selectedAction = 'reply';
        this.refreshParent();
        this.clearForm();
      }).catch(error => {
        // if theres an issue, it will reset, but retain some form values
        this.selectedAction = 'newEmail';
        this.refreshParent();
        this.sendingInProgress = false;
        this.timer_ResetTimer();
      });      

    }
    if (this.selectedAction == 'forward') {

    }
    if (this.selectedAction == 'comment') {
      this.requestApi.threadComment(this.requestDetails.Id, this.newThreadContent, this.attachmentArray, this.timer_ElapsedSeconds()).then(response => {
        this.refreshParent();
        this.clearForm();
      }
    ).catch(error => {
      this.refreshParent();
        this.clearForm();
    });
    }
    if (this.selectedAction == 'resolve') {
      // Reset validation error
      this.resolutionValidationError = null;

      if (this.requestDetails.Required.ImpactRequired && !this.requestDetails.ImpactId)
        this.resolutionValidationError ? this.resolutionValidationError += "Impact, " : this.resolutionValidationError = "Impact, ";

      if (this.requestDetails.Required.UrgencyRequired && !this.requestDetails.UrgencyId)
        this.resolutionValidationError ? this.resolutionValidationError += "Urgency, " : this.resolutionValidationError = "Urgency, ";

      if (this.requestDetails.Required.PriorityRequired && !this.requestDetails.PriorityId)
        this.resolutionValidationError ? this.resolutionValidationError += "Priority, " : this.resolutionValidationError = "Priority, ";

      if (this.requestDetails.Required.ServiceRequired && !this.requestDetails.ServiceId)
        this.resolutionValidationError ? this.resolutionValidationError += "Service, " : this.resolutionValidationError = "Service, ";

      if (!this.requestDetails.AgentId)
        this.resolutionValidationError ? this.resolutionValidationError += "Agent, " : this.resolutionValidationError = "Agent, ";

      if (!this.requestDetails.GroupId)
        this.resolutionValidationError ? this.resolutionValidationError += "Group, " : this.resolutionValidationError = "Group, ";

      if (this.requestDetails.Required.CustomerRequired && !this.requestDetails.CustomerId)
        this.resolutionValidationError ? this.resolutionValidationError += "Customer, " : this.resolutionValidationError = "Customer, ";

      if (this.requestDetails.Required.ResolutionCodesRequired && !this.resolutionCodeId)
        this.resolutionValidationError ? this.resolutionValidationError += "Resolution Code, " : this.resolutionValidationError = "Resolution Code, ";


      this.requestDetails.CustomFields.forEach(field => {
        if (field.Required) {

          if (field.FieldType === 1 || field.FieldType === 3) {

              if (field.Value == null || String(field.Value).trim() === "" || field.tempValue == null || String(field.tempValue).trim() === "" || field.tempValue !== field.Value) {
                  this.resolutionValidationError ?
                      this.resolutionValidationError += `${field.Name},` :
                      this.resolutionValidationError = `${field.Name}, `;
              }
          }

          if (field.FieldType === 2) {

            const levels = field.LevelValues;

            if (levels.Level1SelectedId == null) {
              this.resolutionValidationError ? this.resolutionValidationError += `Level 1  of ${field.Name}, ` : this.resolutionValidationError = `Level 1  of ${field.Name}, `;
            } else {
              const level2Options = field.Options.filter(option => option.ParentCustomFieldId === levels.Level1SelectedId);
              if (level2Options.length > 0 && levels.Level2SelectedId == null) {
                this.resolutionValidationError ? this.resolutionValidationError += `Level 2  of ${field.Name}, ` : this.resolutionValidationError = `  Level 2  of ${field.Name},  `;
              } else {
                const level3Options = field.Options.filter(option => option.ParentCustomFieldId === levels.Level2SelectedId);
                if (level3Options.length > 0 && levels.Level3SelectedId == null) {
                  this.resolutionValidationError ? this.resolutionValidationError += ` Level 3 of ${field.Name}, ` : this.resolutionValidationError = ` Level 3 of ${field.Name}, `;
                }
              }
            }
          }
        }
      });

      if (this.resolutionValidationError) {
        this.sendingInProgress = false;
        return;
      }

      this.requestDetails.IsResolvedEmailNotification = this.IsResolvedEmailNotification ? true : false;

      this.requestApi.threadResolve(this.requestDetails.Id, this.newThreadContent, this.resolutionCodeId,this.requestDetails.IsResolvedEmailNotification, this.attachmentArray, this.timer_ElapsedSeconds()).then(response => {

        if (this.resolveChildTickets) {
          this.requestApi.resolveChildTickets(this.requestDetails.Id).then(response => {
            this.refreshParent();
            this.clearForm();
          })
        } else {
          this.refreshParent();
          this.clearForm();
        }
      },
      error => {
        this.refreshParent();
        this.clearForm();
      }
    );
    }
  }

  clearForm() {
    this.newEmailToAddress = "";
    this.newccfield = [];
    this.attachmentArray = [];
    this.sendingInProgress = false;
    this.resolutionValidationError = null;
    this.timer_ResetTimer();

    if(this.selectedAction !== 'resolve'){
      this.newThreadContent = "";
    }
  }

  refreshParent() {
    this.refreshData.emit();
  }

  changeThread(threadId) {
    this.threadIdChanged.emit(threadId);
  }

}
