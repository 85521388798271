<div id="actions" class="row">

    <!--  <div class="row">-->
    <!--    <div *ngIf="!requestDetails.MIModuleEnabled" class="col col-xl-2">-->
    <!--      <div [class.active]="selectedAction == 'reply'" (click)="selectedAction = 'reply';" class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">-->
    <!--        <p style="font-size: 13px;">Reply</p>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div *ngIf="!requestDetails.MIModuleEnabled" class="col col-xl-2">-->
    <!--      <div  [class.active]="selectedAction == 'newEmail'" (click)="selectedAction = 'newEmail'" class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">-->
    <!--        <p style="font-size: 13px;">New Email</p>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="col col-xl-2">-->
    <!--      <div [class.active]="selectedAction == 'comment'" (click)="selectedAction = 'comment'" class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">-->
    <!--        <p style="font-size: 13px;">Comment</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    -->
    <!--    <div class="col col-xl-2">-->
    <!--      <div [class.active]="selectedAction == 'resolve'" (click)="selectedAction = 'resolve'" class="dashboardTile small divLink text-center" tabindex="0" ng-reflect-router-link="/agent/dashboard/stats" ng-reflect-router-link-active="active" ng-reflect-router-link-active-options="[object Object]">-->
    <!--        <p style="font-size: 13px;">Resolve</p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    -->
    <!--  </div>-->


     <!-- Left bar titles start-->
    <div id="actionTabs" style="width: 140px;">
        <ul class="nav flex-column">
            <!-- Reply section -->
            <li *ngIf="!requestDetails.MIModuleEnabled && !isCmdbEnabled()" class="nav-item divLink">
                <a class="nav-link" [class.active]="selectedAction == 'reply'" (click)="selectedAction = 'reply';">Reply</a>
            </li>
            <!-- New Email section -->
            <li *ngIf="!requestDetails.MIModuleEnabled  && !isCmdbEnabled()" class="nav-item divLink">
                <a class="nav-link" [class.active]="selectedAction == 'newEmail'" (click)="selectedAction = 'newEmail'">New Email</a>
            </li>
            <!-- Comment section -->
            <li class="nav-item divLink">
                <a class="nav-link" [class.active]="selectedAction == 'comment'" (click)="selectedAction = 'comment'">Comment</a>
            </li>
            <!-- Resolve section -->
            <li *ngIf="!isCmdbEnabled()" class="nav-item divLink">
                <a class="nav-link" [class.active]="selectedAction == 'resolve'" (click)="selectedAction = 'resolve'">Resolve</a>
            </li>
        </ul>
    </div>
    <!-- Left bar titles end-->

    <div id="actionContent" class="col sectionBox" style="border-top-left-radius: 0px; padding:30px;">
        <app-loading-spinner *ngIf="sendingInProgress"></app-loading-spinner>

        <input type="file" style="display: none" (change)="onFileSelected($event)" #fileUpload>

        <div *ngIf="!sendingInProgress" id="contentWhenNotLoading">
            <!-- Reply input -->
            <span *ngIf="selectedAction == 'reply' && !isCmdbEnabled()">

                <span *ngIf="selectedAction == 'reply' && selectedThreadId == 1 && !showCc" (click)="showCc = !showCc"
                        class="float-end divLink"><strong>CC</strong></span>

                    <!--          <div  *ngIf="requestThread.Threads.length > 1"><strong>Replying to: </strong>{{getReplyingToPreview()}}</div>-->
                <div><strong>Replying to: </strong>{{getReplyingToPreview()}}</div>
            </span>

            <div *ngIf="selectedAction == 'reply' && selectedThreadId == 1 && showCc && !isCmdbEnabled()" class="tagsContainer">
                <span class="m-r-10"><strong>CC:</strong></span>

                <div *ngFor="let cc of requestDetails.cc" class="sdeskTags">{{cc.EmailAddress}}
                    <i (click)="removeCC(cc.id)" class="fas fa-times divLink"></i>
                </div>


                <div>
                    <app-loading-dots *ngIf="updatingCc"></app-loading-dots>

                    <input *ngIf="!updatingCc" [(ngModel)]="addCcField" class="tagsInput" type="text" placeholder="Add CC" (keyup.enter)="addCC()">

                    <span *ngIf="!updatingCc" class="divLink" (click)="addCC()"
                        style="border: solid 1px lightgray;padding: 5px;margin-left: -1px; font-size: 11px;">Add</span>

                </div>

                <div *ngIf="userSearchService.filteredUsers(this.addCcField).length > 0 && !updatingCc"
                      style="position: absolute;background: white;width: 500px;border: solid #d1d1d1 1px;z-index: 100;
                                       border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;margin-top: -1px;">


                    <div (click)="addCcField = user.EmailAddress; this.addCC()" *ngFor="let user of userSearchService.filteredUsers(this.addCcField)" class="row userSearchRow divLink">
                        <div class="col-5"><strong>{{user.Name}}</strong></div>
                        <div class="col-7">{{user.EmailAddress}}</div>
                    </div>

                </div>
            </div>

            <!-- New Email section start-->
            <div *ngIf="selectedAction == 'newEmail'  && !isCmdbEnabled()" class="row m-b-5">
                <div class="col-1">
                    <p class="p-5"><strong>To: </strong></p>
                </div>
                <div class="col">
                    <!-- <input [(ngModel)]="this.newEmailToAddress" type="email" id="newEmailToAddress" placeholder=""
                 autocomplete="off" class="form-control"> -->
                    <div style="border-radius: 0.25rem">
                        <span class="p-fluid">
                            <p-autoComplete (keydown.enter)="addCustomItem($event, 'to')" [(ngModel)]="newEmailToAddress" 
                            (completeMethod)="filterEmailTo($event)" #typedValue 
                            [suggestions]="suggestedEmailsTo" 
                            field="EmailAddress" [multiple]="true" (completeMethod)="filterEmailTo($event)">
                                <ng-template let-suggestedEmailsTo pTemplate="item">
                                    <div class="row">
                                        <div class="col-md-6">{{suggestedEmailsTo.Name}}</div>
                                        <div class="col-md-6">{{suggestedEmailsTo.EmailAddress}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </span>
                    </div>
                </div>
            </div>


            <div *ngIf="selectedAction == 'newEmail'  && !isCmdbEnabled()" class="row m-b-5">
                <div class="col-md-1">
                    <p class="p-5"><strong>Cc: </strong></p>
                </div>
                <div class="col-md-11">
                    <div style="border-radius: 0.25rem;min-height:33px">
                        <span class="p-fluid">
                            <p-autoComplete (keydown.enter)="addCustomItem($event, 'cc')" [(ngModel)]="newccfield" 
                            [suggestions]="suggestedEmails" field="EmailAddress" [multiple]="true" (completeMethod)="filterEmailCc($event)" (onSelect)="onValueSelect($event)">
                            <ng-template let-suggestedEmails pTemplate="item">
                            <div class="row">
                            <div class="col-md-6">{{suggestedEmails.Name}}</div>
                            <div class="col-md-6">{{suggestedEmails.EmailAddress}}</div>
                            </div>
                            </ng-template>
                            </p-autoComplete>
                        </span>
                    </div>
                </div>
            </div>
            <!-- New Email section end-->

            <!-- Resolve section start-->
            <div *ngIf="selectedAction == 'resolve' && resolutionValidationError  && !isCmdbEnabled()" class="alert alert-danger" ng-cloak>
                <strong>Please fill out the following fields:</strong> {{resolutionValidationError}}
            </div>

            <quill-editor [(ngModel)]="newThreadContent" [styles]="{height: '150px'}" placeholder="" style="display:block;" [modules]="modules" (change)="onEditorChange($event.target.files)" [readOnly]="isCmdbEnabled() && userpermissions?.AssetManagementAdmin == 0"></quill-editor>
            <div *ngIf="!isImageUploaded" class="error-prompt" style="color: red;">Only images are allowed to be uploaded. </div>

            <div *ngIf="selectedAction == 'newEmail'">
                <input [(ngModel)]="forwardOriginalRequest" class="form-check-input" id="forwardOriginalRequest" type="checkbox"><label for="forwardOriginalRequest" class="p-l-5" style="padding-top: 3px;">Forward Original Ticket</label>
            </div>

            <div *ngIf="(selectedAction == 'resolve' || selectedAction == 'reply') && requestDetails.Links.length > 0">
                <input [(ngModel)]="resolveChildTickets" class="form-check-input" id="resolveChildTickets" type="checkbox"><label for="forwardOriginalRequest" class="p-l-5" style="padding-top: 3px;">{{selectedAction == 'resolve' ? 'Resolve' : 'Update' }} all linked tickets</label>
            </div>

            <!--Remove the attachment by onclicking-->
            <div *ngFor="let attachment of attachmentArray; let i = index" (click)="removeTempAttachment(i)" class="btn btn-outline-secondary divLink btn-sm attachmentButton m-r-5 m-t-10">
                <i class="fas fa-file m-r-5"></i>
                <span>{{attachment.AttachmentName}}</span>
            </div>

            <div *ngIf="selectedAction == 'resolve' && requestOptions.RequestTypeOptions.ResolutionCodesEnabled" class="row m-t-5">
                <div class="col-2">
                    <p class="p-5"><strong>Resolution Code </strong></p>
                </div>
                <div class="col-3">
                    <ng-select [items]="requestOptions.resolutionCodes" bindLabel="name" bindValue="id" [(ngModel)]="resolutionCodeId"[clearable]="true">
                    </ng-select>
                </div>
            </div>

        </div>

        <!-- Button section start -->
        <div *ngIf="!sendingInProgress" id="actionContentButtons" class="m-t-5 row">
     <div class="col-md-4">
        <div *ngIf="timer_enabled && !timer_manual" style="padding-top: 10px; padding-bottom: 10px; font-size: 18px">
            <i class="fal fa-stopwatch" style="font-size: 22px"></i> {{msToTime()}} <span class="divLink" style="font-size: 12px;color: #afafaf;" (click)="this.timer_manual = true">(Manual)</span>
        </div>
     </div>
     <div class="col">
        <div style="float: right;">

        <span (click)="escalateToVendor()" *ngIf="requestDetails.CanVendorEscalate" class="p-10 requestBodyButton divLink"><i class="fas fa-level-up-alt m-r-5"></i>Escalate to Vendor</span>
        <!-- Insert Response button -->

        <div class="dropdown" style="display: inline-block;" *ngIf="!isCmdbEnabled()">

            <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                 Insert Response
            </a>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li *ngFor="let cannedResponse of requestDetails.CannedResponses"><a class="dropdown-item divLink" (click)="getCannedResponse(cannedResponse.Id)">{{cannedResponse.Name}}</a>
                </li>
            </ul>
        </div>
        <!-- File upload -->
        <button (click)="fileUpload.click()" class="btn btn-primary" [disabled]="isCmdbEnabled() && userpermissions?.AssetManagementAdmin == 0"><i class="fas fa-paperclip"></i></button>
        <!-- submit button -->
        <button class="btn btn-primary divLink" [disabled]="!newThreadContent" (click)="submit()">{{actionButtonLabel()}}</button>
   </div>
     </div>
    </div>
        <!-- Button section end -->



        <div *ngIf="timer_manual">
            <div class="row">
                <div class="col-2 p-5">
                    <div class="col-1">Hours</div>
                    <div class="col"> <input type="number" [(ngModel)]="timer_manual_Hours" id="hours" name="hours" class="form-control"></div>
                </div>
                <div class="col-2 p-5">
                    <div class="col-1">Minutes</div>
                    <div class="col"> <input type="number" [(ngModel)]="timer_manual_Minutes" id="minutes" name="minutes" class="form-control"></div>
                </div>
                <!--        <div class="col-2 p-5">-->
                <!--          <div class="col-1">Seconds</div>-->
                <!--          <div class="col">  <input type="number" id="seconds" name="seconds" class="form-control"></div>-->
                <!--        </div>-->
            </div>
        </div>
        <div *ngIf="selectedAction == 'resolve'" class="m-t-5 row">
          <div class="col-md-6"></div>
          <div class="col">
              <div style=" margin-top: 4px;"> <!-- Adjust this value as needed -->
                  <input class="form-check-input" type="checkbox" [(ngModel)]="IsResolvedEmailNotification"
                      title="Notify Client on Resolution" checked>
                  <label for="forwardOriginalRequest" class="p-l-5" style="padding-top: 3px;">
                      Notify Client on Resolution
                  </label>
              </div>
          </div>
      </div>
</div>
